"use client";
import { auth, identify } from "@/analytics";
import { AuthPopupClosedError } from "@/analytics/analytics.auth";
import { createRailsHeadersOnClient, render } from "@/api/api";
import { MyUser } from "@/api/entities/myUser";
import { UserSession } from "@/api/entities/userSession";
import { useUser } from "@auth0/nextjs-auth0";
import * as Sentry from "@sentry/nextjs";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

const UserSessionContext = createContext<ICUserSessionValue | null>(null);

type ICUserSessionValue = {
  session: UserSession;
  sessionError?: Error | null;
  isLoadingSession: boolean;
  isLoggedIn: boolean;
  openLoginPopup: (params?: Record<string, string>) => void;
  openSignupPopup: (params?: Record<string, string>) => void;
};

const EMPTY_SESSION = Object.freeze({ user: null });

export function UserSessionProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const auth0State = useUser();

  // Stabilize auth0 values based on meaningful changes only
  const stableAuth0State = useMemo(
    () => ({
      user: auth0State.user,
      isLoading: auth0State.isLoading,
      error: auth0State.error,
    }),
    [auth0State.user, auth0State.error, auth0State.isLoading],
  );

  const { user: auth0User, isLoading: isAuth0Loading } = stableAuth0State;

  const [session, setSession] = useState<UserSession>(EMPTY_SESSION);
  const [isLoadingSession, setIsLoading] = useState(isAuth0Loading);
  const [sessionError, setError] = useState<Error | undefined>(undefined);

  const lastAuth0UserSub = useRef<string | null>(null);

  const reportPostAuthResult = useCallback(
    (authStartedAt?: number, error?: AuthPopupClosedError) => {
      if (authStartedAt) {
        auth.trackAuthPopupClosed({
          duration: Date.now() - authStartedAt,
          success: !error,
          error: error,
        });
      }
    },
    [],
  );

  const fetchSession = useCallback(
    async (authStartedAt?: number) => {
      setIsLoading(true);

      const headers = await createRailsHeadersOnClient();
      if (!headers) {
        setIsLoading(false);
        return;
      }

      const myUserResponse = await render<MyUser>(
        fetch(`${process.env.NEXT_PUBLIC_APP_API_URL}/user/me`, {
          headers,
        }),
      );

      setIsLoading(false);
      if (myUserResponse.status === "error") {
        Sentry.addBreadcrumb({
          category: "auth",
          message: "User session invalid: " + myUserResponse.errorBody,
          level: "info",
        });
        Sentry.setUser(null);
        setError(
          new Error("Failed to fetch session: " + myUserResponse.errorBody),
        );
        setSession(EMPTY_SESSION);
        reportPostAuthResult(authStartedAt, "fetch_session_error");
      } else if (myUserResponse.status === "ok") {
        setError(undefined);
        setSession({ user: myUserResponse.value });

        if (myUserResponse.value) {
          Sentry.setUser({
            id: myUserResponse.value.id,
            email: myUserResponse.value.email,
          });
        } else {
          Sentry.setUser(null);
          Sentry.captureMessage("No user in session");
        }
        reportPostAuthResult(authStartedAt);
      }
    },
    [reportPostAuthResult],
  );

  useEffect(() => {
    if (!isAuth0Loading) {
      setError(undefined);
      if (!auth0User) {
        Sentry.addBreadcrumb({
          category: "auth",
          message: "No auth0 user present.",
          level: "info",
        });
        Sentry.setUser(null);
        setSession(EMPTY_SESSION);
        setIsLoading(false);
        lastAuth0UserSub.current = null;
      } else if (auth0User.sub !== lastAuth0UserSub.current) {
        lastAuth0UserSub.current = auth0User.sub;
        fetchSession();
      }
    }
  }, [auth0User, isAuth0Loading, fetchSession]);

  useEffect(() => {
    identify(session.user || null);
  }, [session]);

  const openAuthPopup = useCallback(
    (isSignup: boolean, params?: Record<string, string>) => {
      if (session.user) {
        Sentry.captureMessage(
          `Attempted to open ${isSignup ? "signup" : "login"} popup while already logged in`,
        );
        return;
      }

      const authStartedAt = Date.now();
      const returnUrl = encodeURIComponent(
        process.env.NEXT_PUBLIC_APP_POPUP_AUTH_RETURN_TO_URL!.toString(),
      );

      let url = `/auth/login?screen_hint=${isSignup ? "signup" : "login"}&returnTo=${returnUrl}`;

      if (params) {
        const queryString = new URLSearchParams(params).toString();
        url += `&${queryString}`;
      }

      const width = 450,
        height = 650;
      const left = (window.screen.width - width) / 2;
      const top = (window.screen.height - height) / 2;
      const popup = window.open(
        url,
        "login-popup",
        `width=${width},height=${height},top=${top},left=${left}`,
      );

      if (!popup) {
        Sentry.captureMessage(`Failed to open signup popup`);
        window.location.href = url;
        return;
      }

      const timer = setInterval(() => {
        if (popup.closed) {
          clearInterval(timer);
          fetchSession(authStartedAt);
        }
      }, 500);
    },
    [fetchSession, session.user],
  );

  const openLoginPopup = useCallback(
    (params?: Record<string, string>) => {
      openAuthPopup(false, params);
    },
    [openAuthPopup],
  );

  const openSignupPopup = useCallback(
    (params?: Record<string, string>) => {
      openAuthPopup(true, params);
    },
    [openAuthPopup],
  );

  const value = useMemo(
    () => ({
      session,
      isLoadingSession,
      isLoggedIn: !!session.user,
      sessionError,
      openLoginPopup,
      openSignupPopup,
    }),
    [session, isLoadingSession, sessionError, openLoginPopup, openSignupPopup],
  );

  return (
    <UserSessionContext.Provider value={value}>
      {children}
    </UserSessionContext.Provider>
  );
}

export function useUserSession(): ICUserSessionValue {
  const context = useContext(UserSessionContext);
  if (!context) {
    throw new Error("useUserSession must be used within a UserSessionProvider");
  }
  return context;
}

export { UserSessionContext };
