import { auth0 } from "@/lib/auth0";

async function safeCreateRailsHeaders() {
  try {
    const { token } = await auth0.getAccessToken();
    if (!token) {
      return undefined;
    }
    return createRailsHeadersFromAccessToken(token);
  } catch (error) {
    return undefined;
  }
}

async function createRailsHeaders() {
  try {
    const { token } = await auth0.getAccessToken();
    if (!token) {
      return undefined;
    }
    return createRailsHeadersFromAccessToken(token);
  } catch (error) {
    return undefined;
  }
}

function createRailsHeadersFromAccessToken(accessToken: string) {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
    cache: "no-store",
  };
}

export {
  createRailsHeaders,
  createRailsHeadersFromAccessToken,
  safeCreateRailsHeaders,
};
